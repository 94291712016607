(function (angular, _, undefined) {
    "use strict";
    var core = angular.module("mikesCore");

    core.controller("mikesCore.ctrl", ["$scope", "$mdMedia", "$mdSidenav", "$localStorage",
        function ($scope, $mdMedia, $mdSidenav, $localStorage) {
        $scope.sidenav = {};
        $scope.sidenav.isLocked = $mdMedia("gt-sm");

        if (!_.has($localStorage, "updated")) {
            $localStorage.$reset();
            $localStorage.updated = (new Date()).getTime();
        } else {
            // clear local storage if more than 1 day week
            if (((new Date()).getTime() - (60 * 60 * 24 * 1000)) > $localStorage.updated) {
                $localStorage.$reset();
            }
        }

        $scope.sidenav.toggle = function () {
            if ($mdMedia("gt-sm")) {
                $scope.sidenav.isLocked = !$scope.sidenav.isLocked;
            } else {
                $mdSidenav("sidenav").toggle();
            }
        };

        $scope.$watch(function () { return $mdMedia("gt-sm"); }, function (now, before) {
            $scope.sidenav.isLocked = now;

            if (now !== before) {
                $mdSidenav("sidenav").close();
            }
        });

    }]);

    core.controller("mikesCore.navbar.ctrl", ["$scope", "$rootScope", "$state", "$mdBottomSheet",
        function ($scope, $rootScope, $state, $mdBottomSheet) {
        var vm = this;

        $rootScope.$state = $state;
        $scope.showBottomSheet = function ($event) {
            $mdBottomSheet.show({
                templateUrl: "/app/core/templates/bottom.html",
                controller: "mikesCore.bottom.ctrl",
                targetEvent: $event
            });
        };

    }]);

    core.controller("mikesCore.navtabs.ctrl", ["$scope", "$location", "$state", "$mdMedia", "$mdUtil",
        function ($scope, $location, $state, $mdMedia, $mdUtil) {
        var vm = this;

        vm.stretch = "always";

        $scope.$watch(function () {
            return $mdMedia("max-width: 390px");
        }, $mdUtil.debounce(function (after) {
            if (after) {
                vm.stretch = "never";
            } else {
                vm.stretch = "always";
            }
        }, 200));

        vm.tabs = [
            "/mike's/blog",
            "/mike's/links",
            "/mike's/projects",
            "/mike's/resume"
        ];

        vm.tabIndex = vm.tabs.indexOf($location.url());

        if (vm.tabIndex < 0) {
            vm.tabIndex = 0;
        }

        vm.gotoState = function (st, opt) {
            $state.go(st, opt);
        };

    }]);

    core.controller("mikesCore.sidenav.ctrl", ["$scope", "$mdSidenav", "$mdMedia", "$http", "$mdToast",
        function ($scope, $mdSidenav, $mdMedia, $http, $mdToast) {
        var vm = this;

        $scope.subscribe = function () {
            $http.post("/api/subscribe", { email: vm.email })
                .then(function (res) {
                    $mdToast.showSimple(_.get(res, "data.message", "Thank you for signing up!"));
                })
                .catch(function (err) {
                    $mdToast.showSimple(_.get(err, "data.message", "Error subscribing!"));
                });
        };

    }]);

    core.controller("mikesCore.bottom.ctrl", ["$scope", "$mdBottomSheet", "$state",
        function ($scope, $mdBottomSheet, $state) {
        $scope.items = [
            { name: "About", icon: "info_outline", state: "about" },
            { name: "license", icon: "description", state: "license" },
            { name: "Privacy Policy", icon: "account_balance", state: "privacy" }
        ];

        $scope.selectItem = function (idx) {
            $state.go($scope.items[idx].state);
            $mdBottomSheet.hide(true);
        };

    }]);

    core.controller("mikesCore.asset.ctrl", ["$scope", "$state", "$localStorage", "AssetService",
        function ($scope, $state, $localStorage, Assets) {
        var vm = this;
        vm.current = _.get($state.current, "name");

        if (!_.has($localStorage, "assets")) {
            $localStorage.assets = {};
        }
        if (!_.has($localStorage.assets, vm.current)) {
            $localStorage.assets[vm.current] = {};
        }
        vm.asset = $localStorage.assets[vm.current];

        Assets.getAsset(vm.current)
            .then(_.noop, function (err) {
                console.error("Error: " + err);
            });
    }]);

})(angular, _);
